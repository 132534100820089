import axios from 'axios';

const apiClient = axios.create({
 // baseURL: 'http://127.0.0.1:7861', // 设置基础URL
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    // 如果有其他通用的headers，可以在这里添加
  },
});

export default {
  chatWithKnowledgeBase(payload) {
    return apiClient.post('/chat/knowledge_base_chat', payload);
  },
  // 新增的方法
  listKnowledgeBases() {
    return apiClient.get('/knowledge_base/list_knowledge_bases');
  },
  deleteKnowledgeBase(knowledgeBaseName) {
    return apiClient.post('/knowledge_base/delete_knowledge_base', JSON.stringify(knowledgeBaseName));
  },
  createKnowledgeBase(knowledgeBaseName) {
    return apiClient.post('/knowledge_base/create_knowledge_base', {
      knowledge_base_name: knowledgeBaseName,
      vector_store_type: "faiss",
      embed_model: "m3e-base"
    });
  },
  listDocs(knowledgeBaseName) {
    return apiClient.get('/knowledge_base/list_files', {
      params: {
        knowledge_base_name: knowledgeBaseName
      }
    });
  },
  deleteDoc(knowledgeBaseName, docName) {
    return apiClient.post('/knowledge_base/delete_doc', {
      knowledge_base_name: knowledgeBaseName,
      doc_name: docName,
      delete_content: false,
      not_refresh_vs_cache: false
    });
  },
  uploadDocToKnowledgeBase(knowledgeBaseName, file, override = false, notRefreshVsCache = false) {
    const formData = new FormData();
    formData.append('files', file);
    formData.append('knowledge_base_name', knowledgeBaseName);
    formData.append('override', override);
    formData.append('not_refresh_vs_cache', notRefreshVsCache);

    return apiClient.post('/knowledge_base/upload_docs', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  updateDocument(knowledgeBaseName, fileName) {
    const data = {
        knowledge_base_name: knowledgeBaseName,
        file_name: fileName,
        not_refresh_vs_cache: false
    };
    return apiClient.post('/knowledge_base/upload_docs', data);
}
};
