
<template>
    <div class="knowledge-base-management">
        <!-- 左侧知识库列表 -->
        <v-card class="knowledge-base-list" elevation="2">
            <v-card-title class="header">
                <v-icon class="knowledgebase-icon">mdi-database</v-icon>
                知识库列表
                <v-spacer></v-spacer>
                <!-- 创建知识库按钮 -->
                <v-btn @click="createKnowledgeBase" small color="primary" dark>
                    <v-icon left>mdi-plus</v-icon>创建知识库</v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-list>
                <v-list-item-group>
                    <v-list-item v-for="kb in knowledgeBases" :key="kb" @click="selectKnowledgeBase(kb)">
                        <v-list-item-content>
                            <v-list-item-title>
                                <v-icon class="knowledgebase-icon">mdi-book-open-page-variant</v-icon>{{ kb
                                }}</v-list-item-title>
                        </v-list-item-content>
                        <!-- 删除按钮 -->
                        <v-list-item-action>
                            <v-btn icon @click.stop="deleteKnowledgeBase(kb)">
                                <v-icon color="red">mdi-delete</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-card>
        <!-- 右侧内容区域 -->
        <v-card class="knowledge-base-content" elevation="2">
            <v-card-title class="header">
                <v-icon class="knowledgebase-icon">mdi-database</v-icon>
                知识库文件内容
            </v-card-title>
            <v-divider></v-divider>

            <!-- 知识库文件列表 -->
            <v-list>
                <v-list-item-group>
                    <v-list-item v-for="file in files" :key="file.name">
                        <v-list-item-content>
                            <v-list-item-title>
                                <v-icon class="knowledgebase-icon">mdi mdi-file-document-outline</v-icon>{{ file.name
                                }}</v-list-item-title>
                        </v-list-item-content>
                        <!-- 更新按钮 -->
                        <v-list-item-action>
                            <v-btn icon @click.stop="updateDocument(file.name)">
                                <v-icon color="blue">mdi-update</v-icon>
                            </v-btn>
                        </v-list-item-action>
                        <!-- 删除按钮 -->
                        <v-list-item-action>
                            <v-btn icon @click.stop="deleteDoc(file.name)">
                                <v-icon color="red">mdi-delete</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
            <!-- 上传文件按钮和加载效果 -->
            <!-- 上传文件区域 -->
            <v-card-actions class="upload-section">
                <v-row align="center" no-gutters>
                    <!-- 文件选择器 -->
                    <v-col cols="12" md="8">
                        <v-file-input label="选择文件" v-model="selectedFile" prepend-icon="mdi-upload" filled></v-file-input>
                    </v-col>
                    <!-- 上传按钮和加载效果 -->
                    <v-col cols="12" md="4" class="text-right">
                        <v-btn @click="uploadFile" color="primary" dark :disabled="isUploading" class="mr-2">上传文件</v-btn>
                        <v-progress-circular v-if="isUploading" indeterminate color="primary"
                            size="24"></v-progress-circular>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
        <!-- 删除知识库的确认对话框 -->
        <v-dialog class="dialog-card" v-model="showDeleteAlert" max-width="500px">
            <v-card>
                <v-card-title class="headline">删除知识库</v-card-title>
                <v-card-text>
                    确定要删除 {{ selectedKnowledgeBaseToDelete }} 吗？
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" dark @click="confirmDelete">删除</v-btn>
                    <v-btn text @click="showDeleteAlert = false">取消</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- 创建知识库的确认对话框 -->
        <v-dialog v-model="showCreateDialog" max-width="500px">
            <v-card class="dialog-card">
                <v-card-title class="dialog-title">创建知识库</v-card-title>
                <v-card-text class="dialog-text">
                    <v-text-field v-model="newKnowledgeBaseName" label="知识库名称" outlined required></v-text-field>
                </v-card-text>
                <v-card-actions class="dialog-actions">
                    <v-btn text @click="showCreateDialog = false">取消</v-btn>
                    <v-btn color="primary" @click="handleCreateKnowledgeBase">创建</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- 删除文档确认对话框 -->
        <v-dialog class="dialog-card" v-model="showDeleteDocDialog" max-width="500px">
            <v-card>
                <v-card-title class="headline">确认删除</v-card-title>
                <v-card-text>
                    确定要删除文档 {{ selectedDocToDelete }} 吗？
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="showDeleteDocDialog = false">取消</v-btn>
                    <v-btn color="red darken-1" text @click="confirmDeleteDoc">删除</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>

import knowledgeBaseApi from '@/api/knowledgeBaseApi';

export default {
    data() {
        return {
            knowledgeBases: [], // 知识库列表
            files: [], // 当前选中的知识库内的文件列表
            selectedKnowledgeBase: null, // 当前选中的知识库
            showDeleteAlert: false,
            selectedKnowledgeBaseToDelete: null,
            showCreateDialog: false, // 控制创建知识库对话框的显示
            newKnowledgeBaseName: '', // 存储新知识库的名称
            showDeleteDocDialog: false, // 控制删除文档确认对话框的显示
            selectedDocToDelete: null, // 要删除的文档名称
            currentKnowledgeBase: null, // 当前选中的知识库名称
            selectedFile: null, // 选中的文件
            isUploading: false, // 是否正在上传文件
        };
    },
    created() {
        this.fetchKnowledgeBases();
    },
    methods: {
        async fetchKnowledgeBases() {
            try {
                const response = await knowledgeBaseApi.listKnowledgeBases();
                if (response.data.code === 200) {
                    this.knowledgeBases = response.data.data;
                } else {
                    console.error('Failed to fetch knowledge bases:', response.data.msg);
                }
            } catch (error) {
                console.error('Error fetching knowledge bases:', error);
            }
        },
        deleteKnowledgeBase(kb) {
            this.selectedKnowledgeBaseToDelete = kb;  // 保存要删除的知识库
            this.showDeleteAlert = true;  // 显示提示框
        },
        async confirmDelete() {
            try {
                const response = await knowledgeBaseApi.deleteKnowledgeBase(this.selectedKnowledgeBaseToDelete);
                if (response.data.code === 200) {
                    this.$toast.success('知识库删除成功！');
                    this.fetchKnowledgeBases();  // 刷新知识库列表
                } else {
                    this.$toast.error('删除知识库失败：' + response.data.msg);
                }
            } catch (error) {
                console.error('删除知识库时出错:', error);
                this.$toast.error('删除知识库时出错');
            }
            this.showDeleteAlert = false;  // 关闭对话框
        },
        async handleCreateKnowledgeBase() {
            try {
                const response = await knowledgeBaseApi.createKnowledgeBase(this.newKnowledgeBaseName);
                if (response.data.code === 200) {
                    this.$toast.success('知识库创建成功！');
                    this.fetchKnowledgeBases();  // 刷新知识库列表
                    this.showCreateDialog = false;  // 关闭对话框
                } else {
                    this.$toast.error('创建知识库失败：' + response.data.msg);
                }
            } catch (error) {
                console.error('创建知识库时出错:', error);
                this.$toast.error('创建知识库时出错');
            }
        },
        // 打开创建知识库的对话框
        createKnowledgeBase() {
            this.newKnowledgeBaseName = '';  // 重置输入框的值
            this.showCreateDialog = true;  // 显示对话框
        },
        async selectKnowledgeBase(kb) {
            this.currentKnowledgeBase = kb;
            try {
                const response = await knowledgeBaseApi.listDocs(kb);
                if (response.data.code === 200) {
                    this.files = response.data.data.map(fileName => ({ name: fileName }));
                } else {
                    this.$toast.error('获取文档列表失败：' + response.data.msg);
                }
            } catch (error) {
                console.error('获取文档列表时出错:', error);
                this.$toast.error('获取文档列表时出错');
            }
        },
        deleteDoc(docName) {
            this.selectedDocToDelete = docName;
            this.showDeleteDocDialog = true;
        },
        async confirmDeleteDoc() {
            try {
                const response = await knowledgeBaseApi.deleteDoc(this.currentKnowledgeBase, this.selectedDocToDelete);
                if (response.data.code === 200) {
                    this.$toast.success('文档删除成功！');
                    // 刷新文档列表
                    this.selectKnowledgeBase(this.currentKnowledgeBase);
                } else {
                    this.$toast.error('删除文档失败：' + response.data.msg);
                }
            } catch (error) {
                console.error('删除文档时出错:', error);
                this.$toast.error('删除文档时出错');
            }
            this.showDeleteDocDialog = false; // 关闭对话框
        },
        async uploadFile() {
            if (!this.selectedFile || !this.currentKnowledgeBase) return;

            this.isUploading = true; // 开始上传

            try {
                const response = await knowledgeBaseApi.uploadDocToKnowledgeBase(
                    this.currentKnowledgeBase,
                    this.selectedFile
                );

                if (response.data.code === 200) {
                    this.$toast.success('文件上传成功！');
                    // 刷新当前知识库的文件列表
                    this.selectKnowledgeBase(this.currentKnowledgeBase);
                } else {
                    this.$toast.error('文件上传失败：' + response.data.msg);
                }
            } catch (error) {
                console.error('上传文件时出错:', error);
                this.$toast.error('上传文件时出错');
            } finally {
                this.isUploading = false; // 上传完成
            }
        },
        async updateDocument(fileName) {
            try {
                const response = await knowledgeBaseApi.updateDocument(this.currentKnowledgeBase, fileName);
                if (response.data.code === 200) {
                    this.$toast.success('文档更新成功！');
                    // 重新加载文档列表
                    this.selectKnowledgeBase(this.currentKnowledgeBase);
                } else {
                    this.$toast.error('文档更新失败：' + response.data.msg);
                }
            } catch (error) {
                console.error('文档更新时出错:', error);
                this.$toast.error('文档更新时出错');
            }
        }
    }
};

</script>

<style>
.knowledge-base-management {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: #f5f5f5;
    overflow-y: hidden;
}

.knowledge-base-list {
    flex: 1;
    min-width: 25%;
    border-right: 1px solid #e0e0e0;
    overflow-y: auto;
    height: 95vh;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.knowledge-base-content {
    flex: 6;
    min-width: 70%;
    border: 1px solid #ccc;
    height: 95vh;
}

.header {
    background-color: #f5f5f5;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    color: #3a5a7d;
    font-weight: bold;
}

v-btn {
    transition: background-color 0.3s ease;
}

v-btn:hover {
    background-color: rgba(63, 81, 181, 0.8);
}

.knowledge-base-management ::v-deep .v-list-item {
    cursor: pointer;
    padding: 10px 15px;
    border-bottom: 1px solid #e0e0e0;
    transition: background-color 0.3s;
    background-color: #f5f5f5;
    margin-bottom: 5px;
    border-radius: 5px;
}

.knowledge-base-management ::v-deep .v-list-item:hover {
    background-color: rgba(63, 81, 181, 0.1);
}

.knowledge-base-management ::v-deep .v-list-item:nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.01);
}

.knowledge-base-management ::v-deep .v-list-item-title {
    font-weight: 500;
}

v-icon {
    transition: color 0.3s ease;
}

v-icon:hover {
    color: #b71c1c;
}

.upload-section {
    padding: 16px;
}

.knowledgebase-icon {
    margin-right: 10px;
}
.dialog-card {
  padding: 20px;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
}

.dialog-title {
  font-weight: bold;
  color: #2c3e50;
}

.dialog-text {
  padding: 20px;
}

.dialog-actions {
  justify-content: flex-end;
}

.dialog-actions > button {
  margin-left: 10px;
}
</style>
