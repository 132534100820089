<!-- src/App.vue -->
<template>
  <v-app>
    <!-- 侧边导航栏 -->
    <v-navigation-drawer class="side-menu"  v-model="drawer" app :permanent="$vuetify.breakpoint.lgAndUp">
      <!-- 标题和Logo区域 -->
      <v-row class="pa-6" align="center" justify="center">
        <v-img src="@/assets/images/logo4.png" contain height="60" alt="Logo"></v-img>
        <v-col cols="auto">
          <h3 class="ml-3 logo-title">AI知识库</h3>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-list>
        <v-list-item-group active-class="blue--text text--accent-4">
          <v-list-group no-action :value="knowledgeBaseOpen">
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon class="menu-icon">mdi-robot</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title id="knowlegebaseList" class="menu-title">知识库AI问答</v-list-item-title>
              </v-list-item-content>
            </template>
            <div class="add-knowledgebase-container">
              <v-btn icon large class="add-knowledgebase-btn" @click="createKnowledgeBase">
                <v-icon class="menu-icon">mdi-plus</v-icon>
              </v-btn>
              <div class="add-knowledgebase-label">创建知识库</div>
            </div>
            <v-list-item v-for="base in knowledgeBases" :key="base" @click="selectKnowledgeBase(base)">
              <v-list-item-icon>
                <v-icon class="menu-icon">mdi-database</v-icon> <!-- 使用文件图标 -->
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="menu-title">{{ base }}</v-list-item-title> <!-- 使用menu-title类来设置字体颜色 -->
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <!-- 新增的知识库管理链接 -->
          <v-list-item @click="goToKnowledgeBaseManagement" class="menu-item">
            <v-list-item-icon>
              <v-icon class="menu-icon">mdi-database</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="menu-title">知识库管理</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <!-- 顶部状态栏 -->
    <v-app-bar app class="top-status-bar" dark>
      <v-btn icon @click="drawer = !drawer" v-if="$vuetify.breakpoint.smAndDown">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-avatar size="32">
          <img src="@/assets/images/user.png" alt="User Avatar">
        </v-avatar>
      </v-btn>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on" class="username-btn">用户名</v-btn>
        </template>
        <v-list>
          <v-list-item @click="logout">
            <v-list-item-title>退出登录</v-list-item-title>
          </v-list-item>
          <!-- 可以添加其他用户操作菜单项 -->
        </v-list>
      </v-menu>
    </v-app-bar>


    <!-- 主内容区域 -->
    <v-main>
      <v-container fluid fill-height>
        <router-view></router-view>
      </v-container>
    </v-main>
    <!-- 创建知识库的确认对话框 -->
    <v-dialog v-model="showCreateDialog" max-width="600px">
      <v-card class="dialog-card">
        <v-card-title class="dialog-title">创建知识库</v-card-title>
        <v-card-text class="dialog-text">
          <v-text-field v-model="newKnowledgeBaseName" label="知识库名称" required outlined></v-text-field>
        </v-card-text>
        <v-card-actions class="dialog-actions">
          <v-btn text @click="showCreateDialog = false">取消</v-btn>
          <v-btn color="primary" dark @click="handleCreateKnowledgeBase">创建</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>


<script>
import knowledgeBaseApi from '@/api/knowledgeBaseApi';
export default {
  created() {
    this.fetchKnowledgeBases();
  },
  data() {
    return {
      drawer: false,
      knowledgeBases: [], // 存储知识库列表
      selectedKnowledgeBase: null, // 当前选中的知识库
      showKnowledgeBaseList: true,
      knowledgeBaseOpen: true,
      showCreateDialog: false, // 控制创建知识库对话框的显示
      newKnowledgeBaseName: '', // 存储新知识库的名称
      // ... 其他数据项
    };
  },
  methods: {
    async fetchKnowledgeBases() {
      try {
        const response = await knowledgeBaseApi.listKnowledgeBases();
        if (response.data.code === 200) {
          this.knowledgeBases = response.data.data;
          if (this.knowledgeBases.length > 0) {
            this.$router.push({ path: '/chat', query: { knowledgeBase: this.knowledgeBases[0] } });
          }
        } else {
          console.error('Failed to fetch knowledge bases:', response.data.msg);
        }
      } catch (error) {
        console.error('Error fetching knowledge bases:', error);
      }
    },
    goToChat() {
      if (this.$route.path !== '/chat') {
        this.$router.push('/chat');
      }
    },
    goToDocuments() {
      if (this.$route.path !== '/documents') {
        this.$router.push('/documents');
      }
    },
    goToKnowledgeBaseManagement() {
      if (this.$route.path !== '/knowledge-base-management') {
        this.$router.push('/knowledge-base-management');
      }
    },
    toggleSubMenu() {
      if (this.selectedKnowledgeBase === 'knowledgeBaseChat') {
        this.selectedKnowledgeBase = null;
      } else {
        this.selectedKnowledgeBase = 'knowledgeBaseChat';
      }
    },
    selectKnowledgeBase(base) {
      // 当用户选择一个知识库时，这个方法将被调用
      this.selectedKnowledgeBase = base; // 设置当前选择的知识库
      // 使用vue-router跳转到ChatBox页面，并传递所选知识库作为参数
      this.$router.push({ path: '/chat', query: { knowledgeBase: base } });
    },
    toggleKnowledgeBaseList() {
      this.showKnowledgeBaseList = !this.showKnowledgeBaseList;
    },
    // 打开创建知识库的对话框
    createKnowledgeBase() {
      this.newKnowledgeBaseName = '';  // 重置输入框的值
      this.showCreateDialog = true;  // 显示对话框
    },
    async handleCreateKnowledgeBase() {
      try {
        const response = await knowledgeBaseApi.createKnowledgeBase(this.newKnowledgeBaseName);
        if (response.data.code === 200) {
          this.$toast.success('知识库创建成功！');
          this.fetchKnowledgeBases();  // 刷新知识库列表
          this.showCreateDialog = false;  // 关闭对话框
        } else {
          this.$toast.error('创建知识库失败：' + response.data.msg);
        }
      } catch (error) {
        console.error('创建知识库时出错:', error);
        this.$toast.error('创建知识库时出错');
      }
    },
  }
}
</script>
<style scoped>
/* 全局样式 */
body,
html,
#app,
.v-application {
  height: 100%;
}


/* 菜单项激活时的颜色 */
.v-navigation-drawer.side-menu .v-list-item-group .v-list-item--active {
  background-color: #3a5a7d;
  /* 稍微淡一些的蓝色背景 */
  color: white;
  /* 白色字体 */
}

.v-navigation-drawer.side-menu .v-list-item-group .v-list-item--active .v-icon {
  color: white;
  /* 白色图标 */
}

/* 菜单项激活时的文字和图标颜色 */
.v-navigation-drawer.side-menu .v-list-item-group .v-list-item--active,
.v-navigation-drawer.side-menu .v-list-item-group .v-list-item--active .v-icon,
.v-navigation-drawer.side-menu .v-list-item-group .v-list-item--active .v-list-item-title {
  color: white !important;
  /* 白色 */
}

/* 顶部状态栏的渐变背景和阴影 */
.v-app-bar.top-status-bar {
  background: linear-gradient(90deg, #3a5a7d, #4c7aa4);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* 用户名按钮的样式 */
.v-btn.username-btn {
  font-size: 1.1em;
  color: white;
  margin-left: 10px;
}

/* 用户头像的样式 */
.v-avatar {
  margin-right: 5px;
}

/* 侧边导航栏的背景 */
.v-navigation-drawer.side-menu {
  background-color: #2c3e50;
  /* 深蓝色背景 */
}

/* 菜单项的样式 */
.v-navigation-drawer.side-menu .v-list-item.menu-item,
.v-navigation-drawer.side-menu .v-list-item {
  font-size: 1.1em;
  transition: background-color 0.3s;
  color: white;
  /* 白色字体 */
}

.v-navigation-drawer.side-menu .v-list-item.menu-item:hover,
.v-navigation-drawer.side-menu .v-list-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
  /* 悬停时的背景颜色 */
}

/* 菜单图标的样式 */
.v-navigation-drawer.side-menu .v-icon.menu-icon,
.v-navigation-drawer.side-menu .v-icon {
  color: white;
  /* 白色 */
  font-size: 1.5em;
}

/* 菜单标题的样式 */
.v-navigation-drawer.side-menu .v-list-item-title.menu-title,
.v-navigation-drawer.side-menu .v-list-item-title {
  color: white;
  /* 白色字体 */
}

/* Logo标题的样式 */
.v-navigation-drawer.side-menu .v-col .logo-title {
  color: white;
  /* 白色字体 */
  font-weight: bold;
}

/* 使知识库菜单和知识库管理菜单的样式一致 */
.v-navigation-drawer.side-menu .v-list-item-group .v-list-item,
.v-navigation-drawer.side-menu .v-list-item-group .v-list-item-content {
  color: white !important;
}

.v-navigation-drawer.side-menu .v-list-item-group .v-list-item-icon .v-icon,
.v-navigation-drawer.side-menu .v-list-item-group .v-list-item-content .v-icon {
  color: white !important;
}

/* 为知识库菜单添加悬停效果 */
.v-navigation-drawer.side-menu .v-list-item-group .v-list-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

#knowlegebaseList {
  color: white !important;
}

.add-knowledgebase-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
}

.add-knowledgebase-btn {
  width: 50px;
  /* 调整为50px */
  height: 50px;
  /* 调整为50px */
  border-radius: 50%;
  background: linear-gradient(135deg, #5f2c82, #49a09d);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.1);
  }
}

.menu-icon {
  font-size: 24px;
  /* 调整图标大小 */
}

.add-knowledgebase-label {
  margin-top: 5px;
  font-size: 1em;
  /* 调整文字大小 */
  color: #ffffff;
  /* 调整为白色以增强对比度 */
  font-weight: bold;
  /* 加粗字体 */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  /* 添加文字阴影以增强可读性 */
}

.dialog-card {
  padding: 20px;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
}

.dialog-title {
  font-weight: bold;
  color: #2c3e50;
}

.dialog-text {
  padding: 20px;
}

.dialog-actions {
  justify-content: flex-end;
}

.dialog-actions>button {
  margin-left: 10px;
}

/* Logo标题的样式 */
.logo-title {
  color: white !important;
  /* 使用 !important 提高优先级 */
  font-weight: bold;
}
</style>