// src/router/index.js

import Vue from 'vue';
import Router from 'vue-router';
import ChatBox from '@/components/ChatBox.vue';
import KnowledgeBaseManagement from '@/components/KnowledgeBaseManagement.vue'; // 引入新的组件

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      redirect: '/chat'
    },
    {
      path: '/chat',
      component: ChatBox
    },
    {
      path: '/knowledge-base-management', // 新的路由路径
      component: KnowledgeBaseManagement // 使用的组件
    }
  ]
});
