import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import '@mdi/font/css/materialdesignicons.min.css'
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

// 使用默认配置
Vue.use(Toast);
Vue.config.productionTip = false

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')



