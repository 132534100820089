<!-- src/components/ChatBox.vue -->

<template>
  <div class="chat-container">
    <div class="knowledgebase-container">
      <!-- 知识库标题和图标区域 -->
      <div>
        <v-row align="center" class="knowledgebase-header mb-3">
          <v-col cols="2">
            <v-icon class="knowledgebase-icon">mdi-database</v-icon> <!-- 使用数据库图标 -->
          </v-col>
          <v-col cols="auto">
            <h3 class="knowledgebase-title">{{ selectedKnowledgeBase }}</h3>
          </v-col>
          <v-col cols="1">
            <v-btn small color="info" @click="showUploadDialog = true">
              <v-icon id="white-icon">mdi-plus</v-icon>
              添加文档
            </v-btn>
          </v-col>
        </v-row>
        <v-divider></v-divider>
      </div>
      <!-- <div id="knowledgebaseDocs">
        <h3>
          <span class="mdi mdi-folder-open-outline"></span>
          知识库文档
        </h3>
      </div> -->
      <ul id="docList">
        <li v-for="doc in documents" :key="doc">
          <a @click="handleDocumentClick(doc)">
            <span class="mdi mdi-file-document-outline"></span>
            {{ doc }}
          </a>
        </li>
      </ul>
    </div>
    <div class="document-container">
      <div class="document-header">
        {{ documentTitle }}
      </div>
      <!-- 文档显示区域 -->
      <div class="document-content">
        <div v-if="documentType === 'pdf'">
          <iframe ref="pdfFrame" id="pdfFrame" :src="viewerPath + '?file=' + encodeURIComponent(pdfFilePath)"
            width="100%"></iframe>
        </div>
        <div v-if="documentType === 'docx' || documentType === 'doc'">
          <!-- Word显示组件，例如使用mammoth.js -->
          <div ref="wordContainer"></div>
        </div>
        <div v-if="documentType === 'md'">
          <!-- Markdown显示组件，例如使用markdown-it -->
          <div ref="markdownContainer"></div>
        </div>
        <!-- TXT显示组件 -->
        <div v-if="documentType === 'txt'" ref="txtContainer"></div>
        <!-- HTML显示组件 -->
        <iframe v-if="documentType === 'html'" ref="htmlFrame" width="100%"></iframe>
        <v-progress-circular v-if="isLoadingDocument" indeterminate></v-progress-circular>
      </div>
    </div>
    <div class="chat-content">
      <!-- 知识库标题和图标区域 -->
      <div>
        <v-row align="center" class="knowledgebase-header mb-3" no-gutters>
          <v-col cols="auto">
            <v-icon class="knowledgebase-icon">mdi-database</v-icon>
          </v-col>
          <v-col cols="auto">
            <h3 class="knowledgebase-title">当前知识库: {{ selectedKnowledgeBase }}</h3>
          </v-col>
          <v-spacer></v-spacer>
          <v-col v-if="!$vuetify.breakpoint.mdAndUp" cols="1" class="folder-icon-col">
            <v-btn icon @click="showKnowledgeBaseDialog = true">
              <v-icon>mdi-folder</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-divider></v-divider>
      </div>
      <div class="chat-messages">
        <div v-for="(message, index) in chatMessages" :key="index" :class="message.role">
          <v-avatar :size="40" class="avatar">
            <img
              :src="message.role === 'user' ? require('@/assets/images/user.png') : require('@/assets/images/ai-logo.png')">
          </v-avatar>
          <div class="bubble">
            {{ message.content }}
            <v-icon v-if="message.role === 'assistant'" @click="speak(message.content)">mdi-volume-high</v-icon>
            <div v-if="message.role === 'assistant' && isLoading && index === lastAssistantMessageIndex"
              class="loading-spinner"></div>
            <!-- 将引用部分移到这里 -->
            <div v-if="message.role === 'assistant'" class="reference-section">
              <div v-if="!message.showFullReference" @click="toggleReference(index)">
                找到了 {{ message.docs.length }} 处引用
              </div>
              <div v-else>
                <div v-for="(doc, docIndex) in message.docs" :key="docIndex">
                  <div v-for="link in parseDocumentLinks(doc)" :key="link.url">
                    <a @click="handleDocumentClick(link.name)">{{ link.name }}</a>
                  </div>
                  <div v-html="doc.replace(/\[([^\]]+)\]\(([^)]+)\)/g, '$1')"></div>
                  <div v-if="extractPageNumbers(doc).length > 0" class="reference-pages">
                    <span class="reference-label">引用页码: </span>
                    <a v-for="pageNumber in extractPageNumbers(doc)" :key="pageNumber" @click="goToPageInPDF(pageNumber)"
                      class="page-link">
                      {{ pageNumber }}
                    </a>
                  </div>
                </div>
                <div class="toggle-button" @click="toggleReference(index)">点击隐藏全部引用</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="input-container">
        <v-text-field label="输入消息" v-model="newMessage" @keyup.enter="sendMessage" outlined>
          <!-- 使用 append 插槽将按钮放置在输入框的最右侧 -->
          <template v-slot:append>
            <!-- 麦克风按钮 -->
            <v-btn class="send-button" @mousedown="startRecording" @mouseup="stopRecording" @touchstart="startRecording"
              @touchend="stopRecording">
              <v-icon :class="{ 'recording': isRecording }">mdi-microphone</v-icon>
            </v-btn>
            <v-btn class="send-button" color="primary" icon @click="sendMessage">
              <v-icon>mdi-send</v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </div>
      <div class="recording-animation" v-if="isRecording">
      <v-icon class="recording-icon">mdi-microphone</v-icon>
    </div>
    </div>
    <v-dialog v-model="showUploadDialog" max-width="600px">
      <v-card class="dialog-card">
        <v-card-title class="dialog-title">上传文件到知识库</v-card-title>
        <v-card-text class="dialog-text">
          <v-file-input v-model="selectedFile" label="选择文件" outlined></v-file-input>
        </v-card-text>
        <v-card-actions class="dialog-actions">
          <v-btn text @click="showUploadDialog = false">取消</v-btn>
          <v-btn @click="uploadFile" color="primary" dark :disabled="isUploading" class="mr-2">上传文件</v-btn>
          <v-progress-circular v-if="isUploading" indeterminate color="primary" size="24"></v-progress-circular>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showKnowledgeBaseDialog" fullscreen transition="dialog-bottom-transition">
      <v-card>
        <!-- 标题区域 -->
        <v-toolbar id="top-status-bar"> <!-- 使用您提供的渐变背景样式 -->
          <v-btn icon dark @click="showKnowledgeBaseDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="headline"> <v-icon color="white">mdi-folder</v-icon></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark @click="showUploadDialog = true">
            <v-icon left>mdi-plus</v-icon>
            添加文档
          </v-btn>
        </v-toolbar>

        <!-- 知识库文件列表内容 -->
        <v-list>
          <v-list-item-group>
            <v-list-item v-for="(doc, index) in documents" :key="doc" @click="handleDocumentClick(doc)"
              :class="index % 2 === 0 ? 'list-item-even' : 'list-item-odd'">
              <v-list-item-avatar>
                <v-icon>mdi-file-document-outline</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ doc }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-dialog>




  </div>
</template>

<script>
import axios from 'axios';
import * as pdfjs from 'pdfjs-dist/build/pdf';
import 'pdfjs-dist/web/pdf_viewer.css';
import mammoth from 'mammoth';
import marked from 'marked';
import knowledgeBaseApi from '@/api/knowledgeBaseApi';

//import chatAPI from '@/api/chatAPI';
// 设置Web Worker的路径
pdfjs.GlobalWorkerOptions.workerSrc = require('pdfjs-dist/build/pdf.worker.js');
export default {
  data() {
    return {
      viewerPath: process.env.BASE_URL + 'pdfjs/web/viewer.html',
      newMessage: '',
      documentType: '',
      knowledgeBases: [],
      documents: [],
      documentTitle: '文档标题',
      selectedKnowledgeBase: '',
      isLoading: false,
      lastAssistantMessageIndex: null,
      currentPage: 1,
      totalPages: 0,
      pdfDocument: null,
      canvasList: [],
      pdfFilePath: '',
      showUploadDialog: false, // 控制文件上传对话框的显示/隐藏
      selectedFile: null, // 用户选择的文件
      isUploading: false, // 是否正在上传文件
      isLoadingDocument: false,
      showKnowledgeBaseDialog: false,  // 控制知识库文件列表浮框的显示
      message: '',
      recognition: null,  // 用于语音识别的对象
      isRecording: false,
    };
  },
  computed: {
    chatMessages() {
      return this.$store.state.chatMessages;
    }
  },
  mounted() {
    console.log("this.$route.query.knowledgeBase", this.$route.query.knowledgeBase)
    this.selectedKnowledgeBase = this.$route.query.knowledgeBase;
    this.onKnowledgeBaseChange();

    // 根据接收的知识库参数加载相关数据或执行其他操作
  },
  created() {
    this.fetchKnowledgeBases();
  },
  methods: {
    async fetchKnowledgeBases() {
      try {
        const response = await axios.get(`/knowledge_base/list_knowledge_bases`);
        if (response.data.code === 200) {
          this.knowledgeBases = response.data.data;
        } else {
          console.error('Failed to fetch knowledge bases:', response.data.msg);
        }
      } catch (error) {
        console.error('Error fetching knowledge bases:', error);
      }
    },
    async sendMessage() {
      // 首先，将用户的消息添加到聊天历史中
      this.$store.commit('ADD_MESSAGE', {
        role: 'user',
        content: this.newMessage,
      });

      const payload = {
        query: this.newMessage,
        knowledge_base_name: this.selectedKnowledgeBase,
        top_k: 5,
        score_threshold: 1,
        history: this.chatMessages,
        stream: true,
        local_doc_url: false,
      };

      this.newMessage = ''; // 清空输入框

      try {
        this.$store.commit('ADD_MESSAGE', {
          role: 'assistant',
          content: '',
          docs: [], // 假设你的返回数据中有一个docs字段
          showFullReference: false
        });

        this.isLoading = true; // 开始加载 
        this.lastAssistantMessageIndex = this.chatMessages.length - 1;
        const response = await fetch('/chat/knowledge_base_chat', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });
        const reader = response.body.getReader();
        let decoder = new TextDecoder();
        let accumulatedData = "";
        // ... 其他代码 ...
        // eslint-disable-next-line no-constant-condition
        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            console.log("Final accumulatedData", accumulatedData);
            break;
          }

          accumulatedData += decoder.decode(value, { stream: true });

          // 尝试查找完整的JSON对象
          let startPos = accumulatedData.indexOf('{');
          let endPos = accumulatedData.indexOf('}', startPos);

          while (startPos >= 0 && endPos >= 0) {
            const jsonString = accumulatedData.substring(startPos, endPos + 1);
            try {
              const parsedData = JSON.parse(jsonString);
              this.isLoading = false; // 结束加载
              if (parsedData && parsedData.answer) {
                // 更新UI
                const lastMessageIndex = this.chatMessages.length - 1;
                await new Promise(resolve => setTimeout(resolve, 100)); // 添加100毫秒的延迟
                this.$store.commit('UPDATE_MESSAGE', {
                  index: lastMessageIndex,
                  content: this.chatMessages[lastMessageIndex].content + parsedData.answer.replace(/"/g, ''),
                  docs: parsedData.docs || [],
                });
                console.log("docs", parsedData.docs, parsedData);
              } else if (parsedData && parsedData.docs) {
                const lastMessageIndex = this.chatMessages.length - 1;
                this.$store.commit('UPDATE_MESSAGE', {
                  index: lastMessageIndex,
                  content: this.chatMessages[lastMessageIndex].content,
                  docs: parsedData.docs || [],
                });
              }
              accumulatedData = accumulatedData.substring(endPos + 1);
              startPos = accumulatedData.indexOf('{');
              endPos = accumulatedData.indexOf('}', startPos);
            } catch (error) {
              break;
            }
          }
        }

      } catch (error) {
        console.error('Error streaming chat response:', error);
      }
    },
    isValidJSON(str) {
      try {
        JSON.parse(str);
        return true;
      } catch (e) {
        return false;
      }
    },
    toggleReference(index) {
      this.$store.commit('TOGGLE_REFERENCE', index);
    },
    //文档显示区域
    async displayDocument(documentPath, type) {
      this.documentType = type;
      this.isLoadingDocument = true; // 开始加载
      try {
        // 提取 file_name 参数的值
        const url = new URL(documentPath);
        const params = new URLSearchParams(url.search);
        this.documentTitle = params.get('file_name');
        if (type === 'pdf') {
          this.pdfFilePath = documentPath; // 设置要显示的PDF文件的路径
        } else if (type === 'docx' || type === 'doc') {
          // 使用 mammoth.js 显示 Word
          try {
            const response = await fetch(documentPath);
            const arrayBuffer = await response.arrayBuffer();
            const result = await mammoth.convertToHtml({ arrayBuffer: arrayBuffer });
            this.$refs.wordContainer.innerHTML = result.value;  // 假设你在data()中有一个wordContent变量来存储转换后的HTML
          } catch (error) {
            console.error("Error displaying Word document:", error);
          }
          // const result = await mammoth.convertToHtml({ path: documentPath });
          // this.$refs.wordContainer.innerHTML = result.value;
        } else if (type === 'md') {
          // 使用 marked 显示 Markdown
          const response = await fetch(documentPath);
          const markdownContent = await response.text();
          const htmlContent = marked(markdownContent);
          this.$refs.markdownContainer.innerHTML = htmlContent;
        } else if (type === 'txt') {
          // 使用 fetch 获取 TXT 内容并显示
          const response = await fetch(documentPath);
          const txtContent = await response.text();
          this.$refs.txtContainer.innerText = txtContent;
        } else if (type === 'html') {
          // 使用 iframe 显示 HTML 文档
          this.$refs.htmlFrame.src = documentPath;
        }
      } catch (error) {
        console.error("Error displaying document:", error);
      } finally {
        this.isLoadingDocument = false; // 结束加载
      }
    },
    async goToPage(pageNumber) {
      if (pageNumber < 1 || pageNumber > this.totalPages) return;

      this.currentPage = pageNumber;
      const page = await this.pdfDocument.getPage(this.currentPage);

      const scale = 1.5;
      const viewport = page.getViewport({ scale });

      const canvas = this.$refs.pdfCanvas;
      const context = canvas.getContext('2d');
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      const renderContext = {
        canvasContext: context,
        viewport: viewport
      };
      await page.render(renderContext);
    },
    parseDocumentLinks(doc) {
      const regex = /\[([^\]]+)\]\((http[^\s]+)\)/g;
      const links = [];
      let match;
      while ((match = regex.exec(doc)) !== null) {
        links.push({
          name: match[1],
          url: match[2],
          type: match[2].split('.').pop() // 获取文件扩展名作为类型
        });
      }
      return links;
    },
    // searchReferenceInPDF(query) {
    //   const iframeWindow = this.$refs.pdfFrame.contentWindow;
    //   if (iframeWindow && typeof iframeWindow.searchInPDF === 'function') {
    //     iframeWindow.searchInPDF(query);
    //   }
    // }
    extractPageNumbers(doc) {
      const regex = /\n\n(\d+)\n\n/g;
      const matches = doc.match(regex);
      return matches ? matches.map(match => parseInt(match.trim())) : [];
    },
    goToPageInPDF(pageNumber) {
      this.$refs.pdfFrame.contentWindow.PDFViewerApplication.pdfViewer.currentPageNumber = pageNumber;
    },
    onKnowledgeBaseChange() {
      if (this.selectedKnowledgeBase) {
        // 使用axios或其他HTTP客户端获取文件列表
        knowledgeBaseApi.listDocs(this.selectedKnowledgeBase)
          .then(response => {
            this.documents = response.data.data; // 假设API返回一个文件列表数组
            if (this.documents) {
              this.handleDocumentClick(this.documents[0])
            }
          })
          .catch(error => {
            console.error("Error loading documents:", error);
          });
      } else {
        this.documents = [];
      }
    },
    handleDocumentClick(fileName) {
      const baseUrl = process.env.VUE_APP_KNOW_BASE_URL;
      const fileUrl = `${baseUrl}?knowledge_base_name=${encodeURIComponent(this.selectedKnowledgeBase)}&file_name=${encodeURIComponent(fileName)}`;
      const fileType = fileName.split('.').pop(); // 获取文件扩展名，例如"pdf"

      // 使用构建的fileUrl和fileType调用displayDocument方法
      this.displayDocument(fileUrl, fileType);
    },
    loadKnowledgeBase() {
      // 当路由参数knowledgeBaseName发生变化时，执行这里的逻辑
      // 例如重新加载数据等
      console.log("loadKnowledgeBase");
      this.selectedKnowledgeBase = this.$route.query.knowledgeBase;
      this.onKnowledgeBaseChange();
    },
    async uploadFile() {
      if (!this.selectedFile || !this.selectedKnowledgeBase) return;

      this.isUploading = true; // 开始上传

      try {
        const response = await knowledgeBaseApi.uploadDocToKnowledgeBase(
          this.selectedKnowledgeBase,
          this.selectedFile
        );

        if (response.data.code === 200) {
          this.$toast.success('文件上传成功！');
          // 刷新当前知识库的文件列表
          this.loadKnowledgeBase();
          this.showUploadDialog = false;
          this.selectedFile = null;

        } else {
          this.$toast.error('文件上传失败：' + response.data.msg);
        }
      } catch (error) {
        console.error('上传文件时出错:', error);
        this.$toast.error('上传文件时出错');
      } finally {
        this.isUploading = false; // 上传完成
        this.showUploadDialog = false;
      }
    },
    speak(text) {
      if ('speechSynthesis' in window) {
        // 取消所有当前的语音合成任务
        window.speechSynthesis.cancel();

        const utterance = new SpeechSynthesisUtterance();
        utterance.rate = 1;
        utterance.pitch = 1;
        utterance.text = text;
        utterance.volume = 1;
        utterance.lang = 'zh-CN';

        // 添加 onend 和 onerror 事件监听器
        utterance.onend = function (event) {
          console.log('SpeechSynthesisUtterance has finished being spoken.', event);
        }
        utterance.onerror = function (event) {
          console.error('SpeechSynthesisUtterance encountered an error:', event);
        }

        // 使用 setTimeout 延迟调用
        setTimeout(() => {
          window.speechSynthesis.speak(utterance);
        }, 100);
      } else {
        console.warn('Your browser does not support speech synthesis.');
      }
    },
    startRecording() {
      this.isRecording = true;
      // 检查浏览器是否支持语音识别
      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
      if (!SpeechRecognition) {
        alert('您的浏览器不支持语音识别。');
        return;
      }

      // 创建语音识别对象
      this.recognition = new SpeechRecognition();
      this.recognition.lang = 'zh-CN';  // 设置语言为中文
      this.recognition.interimResults = false;  // 只返回最终结果

      // 开始语音识别
      this.recognition.start();

      // 当识别到结果时
      this.recognition.onresult = (event) => {
        const transcript = event.results[0][0].transcript;
        this.newMessage = transcript;  // 将识别到的文本设置为消息内容
        this.sendMessage();  // 自动发送消息

      };
    },
    stopRecording() {
      // 停止语音识别
      this.isRecording = false;
      if (this.recognition) {
        setTimeout(() => {
          this.recognition.stop();
        }, 500); // 延迟1秒
      }
    },
  },
  watch: {
    selectedKnowledgeBase: 'onKnowledgeBaseChange',
    '$route.query.knowledgeBase': 'loadKnowledgeBase'
  }
}
</script>
<style scoped>
.chat-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  /* 修改为行布局 */
  overflow-y: hidden;
}

.document-container {
  flex: 6;
  /* 代表70% */
  min-width: 60%;
  /* 保证在没有内容时仍然保持其宽度 */
  border: 1px solid #ccc;
  height: 95vh;
  /* 调整这个值以适应您的布局 */
  max-height: 95vh;
  /* 调整这个值以适应您的布局 */
  border: 1px solid #e0e0e0;
  /* 添加边框 */
  border-radius: 5px;
  /* 圆角 */
  margin: 10px 5px;
  /* 上下边距为10px，左右边距为5px */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* 轻微的阴影效果 */
  overflow: hidden;
  /* 隐藏超出边框的内容 */

}

.document-header {
  background-color: #f5f5f5;
  /* 灰色背景 */
  padding: 10px;
  /* 添加一些内部间距 */
  font-weight: bold;
  /* 加粗字体 */
  border-bottom: 1px solid #ccc;
  /* 添加一个底部边框 */
  position: sticky;
  /* 使标题固定在顶部 */
  top: 0;
  /* 与顶部对齐 */
  z-index: 10;
  /* 确保标题始终在内容的上方 */
  text-align: center;
}

.document-content {
  max-height: calc(100vh - 70px);
  /* 考虑到标题和其他元素的高度 */
  overflow-y: auto;
  /* 允许垂直滚动 */
  padding: 10px;
  /* 添加一些内部间距 */
}

.chat-content {
  flex: 4;
  height: 100%;
  /* 设置为100% */
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  /* 或其他适当的值，确保它占据其父元素的全部高度 */
  height: calc(100vh - 100px);
  /* 调整这个值以适应您的布局 */
  max-height: calc(100vh - 100px);
  /* 调整这个值以适应您的布局 */
  border: 1px solid #e0e0e0;
  /* 添加边框 */
  border-radius: 5px;
  /* 圆角 */
  margin: 5px 5px;
  /* 上下边距为10px，左右边距为5px */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* 轻微的阴影效果 */
  overflow: hidden;
  /* 隐藏超出边框的内容 */
}

.input-container {
  display: flex;
  align-items: center;
  background-color: white;
}

.bubble {
  padding: 10px 20px;
  border-radius: 20px;
  margin-bottom: 10px;
  position: relative;
  max-width: 70%;
}

.bubble>.mdi-volume-high {
  position: absolute;
  right: -30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.user,
.assistant {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
}

.user .bubble {
  background-color: #1976D2;
  color: white;
  border-top-right-radius: 5px;
  order: 1;
}

.assistant .bubble {
  background-color: #E0E0E0;
  border-top-left-radius: 5px;
  order: 1;
}

.avatar {
  margin-right: 10px;
  margin-left: 10px;
  align-self: center;
  order: 1;
}

.user {
  justify-content: flex-end;
  flex-direction: row;
}

.user .avatar {
  margin-left: 0.5;
  order: 2;
}

.assistant .avatar {
  margin-right: 0.5;
  order: 1;
}

.loading-spinner {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid transparent;
  border-top-color: #3498db;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/**
引用部分
*/
.reference-section {
  margin-top: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #f7f7f7;
  font-size: 0.9em;
  color: #555;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.reference-section:hover {
  background-color: #ebebeb;
}

.reference-section div {
  margin-bottom: 5px;
}

.reference-section div:last-child {
  margin-bottom: 0;
}

.reference-section .toggle-button {
  display: inline-block;
  margin-top: 5px;
  padding: 3px 8px;
  border-radius: 15px;
  background-color: #555;
  /* 深灰色背景 */
  color: #f0f0f0;
  /* 浅灰色文字 */
  font-weight: bold;
  /* 加粗文字 */
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.reference-section .toggle-button:hover {
  background-color: #333;
  /* 更深的灰色背景 */
}

.chat-messages {
  flex: 1;
  /* 允许它填充可用空间 */
  overflow-y: auto;
  /* 允许这个区域滚动 */
  padding: 10px;
  /* 添加一些内部间距 */
}

.chat-container {
  overflow-y: auto;
  height: 100%;
  /* 或者其他适当的高度值 */
}

.input-container {
  display: flex;
  align-items: center;
  background-color: white;
  border-top: 1px solid #e0e0e0;
  /* 添加分割线 */
  /* 添加分割线 */
}

#pdfFrame {
  width: 100%;
  height: calc(100vh - 70px - 20px);
  /* 考虑到 `.document-content` 的上下内边距总和 */
  border: none;
  display: block;
  /* 确保它是块级元素，以消除任何默认的边距 */
}

.reference-pages {
  display: inline-block;
}

.reference-label {
  font-weight: bold;
  color: #333;
  margin-right: 5px;
}

.page-link {
  color: #007BFF;
  text-decoration: underline;
  cursor: pointer;
  margin-right: 5px;
}

.knowledgebase-container {
  flex: 2;
  border-right: 1px solid #ccc;
  padding: 20px;
  /* 增加内边距 */
  overflow-y: auto;
  background-color: #f9f9f9;
  /* 轻微的背景颜色 */
}

#knowledgebaseDocs h3 {
  background-color: #f5f5f5;
  padding: 15px;
  /* 增加内边距 */
  font-weight: bold;
  border-bottom: 1px solid #ccc;
  text-align: center;
  margin-bottom: 20px;
  /* 增加下方留白 */
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.knowledgebase-container ul {
  list-style-type: none;
  padding: 0;
}

.knowledgebase-container li {
  padding: 10px 15px;
  /* 增加列表项内边距 */
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  /* 添加过渡效果 */
  font-size: 16px;
  /* 调整字体大小 */
}

.knowledgebase-container li:hover {
  background-color: #e7e7e7;
  /* 调整鼠标悬停时的背景颜色 */
  transform: scale(1.02);
  /* 添加放大效果 */
}

.knowledgebase-container a {
  color: #333;
  /* 调整链接颜色 */
  text-decoration: none;
  /* 去除下划线 */
}

.knowledgebase-container a:hover {
  text-decoration: underline;
  /* 鼠标悬停时添加下划线 */
}

#docList i {
  margin-right: 10px;
  /* 图标和文本之间的间距 */
  color: #007BFF;
  /* 图标颜色 */
}

#docList .mdi {
  margin-right: 10px;
  /* 图标和文本之间的间距 */
  color: #007BFF;
  /* 图标颜色 */
  font-size: 18px;
  /* 调整图标大小 */
}

.knowledgebase-container {
  border: 1px solid #e0e0e0;
  /* 添加边框 */
  border-radius: 5px;
  /* 添加圆角 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* 轻微的阴影效果 */
  margin-right: 10px;
  /* 调整右侧留白 */
}

#knowledgebaseDocs h3 {
  /* ... 保持之前的样式不变 ... */
  display: flex;
  /* 使用 Flexbox 布局 */
  align-items: center;
  /* 垂直居中图标和文本 */
}

#knowledgebaseDocs h3 .mdi {
  margin-right: 10px;
  /* 图标和文本之间的间距 */
  color: #007BFF;
  /* 图标颜色 */
  font-size: 24px;
  /* 调整图标大小 */
}

.knowledgebase-header {
  background-color: #f5f5f5;
  /* 灰色背景 */
  padding: 10px 20px;
  /* 添加一些内部间距 */
  border-radius: 5px;
  /* 圆角 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* 轻微的阴影效果 */
}

.knowledgebase-icon {
  color: #3a5a7d;
  /* 蓝色图标 */
  font-size: 2em;
  /* 图标大小 */
  margin-right: 10px;
  /* 图标和标题之间的间距 */
}

.knowledgebase-title {
  font-weight: bold;
  /* 加粗字体 */
  color: #3a5a7d;
  /* 蓝色标题 */
}

#white-icon {
  font-size: 16px;
}

.dialog-card {
  padding: 20px;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
}

.dialog-title {
  font-weight: bold;
  color: #2c3e50;
}

.dialog-text {
  padding: 20px;
}

.dialog-actions {
  justify-content: flex-end;
}

.dialog-actions>button {
  margin-left: 10px;
}

#top-status-bar {
  background: linear-gradient(90deg, #3a5a7d, #4c7aa4);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.headline {
  color: white;
}

/* 交替背景颜色 */
.list-item-even {
  background-color: #f5f5f5;
  /* 淡灰色背景 */
}

.list-item-odd {
  background-color: #ffffff;
  /* 白色背景 */
}

.folder-icon-col {
  padding-right: 4px;
  /* 调整右侧内边距 */
}

.send-button {
  margin-top: -8px;
  /* 根据需要调整这个值 */
}

.recording-animation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000; /* 确保动画显示在其他内容的上方 */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border-radius: 50%;
}

.recording-icon {
  font-size: 48px;
  color: white;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}



@keyframes recordingAnimation {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.recording {
  animation: recordingAnimation 1s infinite;
}

/* 当屏幕宽度小于或等于768px时（通常是手机和小型设备） */
@media (max-width: 768px) {
  .document-container {
    display: none;
    /* 隐藏文档显示区域 */
  }

  .knowledgebase-container {
    display: none;
    /* 隐藏文档显示区域 */
  }
}
</style>