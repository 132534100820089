// src/store/index.js

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    chatMessages: [],
    documents: []
  },
  mutations: {
    ADD_MESSAGE(state, message) {
      state.chatMessages.push(message);
    },
    ADD_DOCUMENT(state, document) {
      state.documents.push(document);
    },
    UPDATE_MESSAGE(state, { index, content, docs }) {
      if (state.chatMessages[index]) {
        state.chatMessages[index].content = content;
        if (docs) {
          state.chatMessages[index].docs = docs;
        }
      }
    },
    TOGGLE_REFERENCE(state, index) {
      state.chatMessages[index].showFullReference = !state.chatMessages[index].showFullReference;
    }
  },
  actions: {
    addMessage({ commit }, message) {
      commit('ADD_MESSAGE', message);
    },
    addDocument({ commit }, document) {
      commit('ADD_DOCUMENT', document);
    }
  }
})
